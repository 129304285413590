import "../../common/polyfills";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { IGalleryProps } from "./Contracts/contracts";
import { Gallery } from "./Components/Gallery";

function MountGallery(props: IGalleryProps, el: Element) {
    ReactDOM.render(<Gallery {...props} />, el)
}

(window as any).MountGallery = MountGallery;