import "../../../common/polyfills";
import * as React from 'react';
import { useState, useEffect } from 'react';
import '../Style/Video.scss';
import LoadingOverlay from "react-loading-overlay";
import VideoPlayer from 'react-video-js-player';

interface IThreePointsProp {
  playTime: number;
  videoTextSections: ISectionProps[];
  className?: string;
}
interface ISectionProps {
  Header: string;
  Description: string;
  Delay: number;
}

const ThreePoints = (props: IThreePointsProp) => {
  const time = props.playTime
  return <div className={props.className} >{props.videoTextSections.map((section: ISectionProps, i: number) => (
    <div className={`point${i + 1} textSection`} key={i + 1}>
      <h3 className={`sectionHeader ${time >= section.Delay ? 'sectionHeaderVisible' : 'sectionHeaderHidden'}`}>{section.Header}</h3>
      <p className={`sectionDescription ${time >= section.Delay ? 'sectionDescriptionVisible' : 'sectionDescriptionHidden'}`}>{section.Description}</p>
    </div>))}
  </div>
}

export interface IVideoProps extends IVideoAttributes {
  videoUrl: string;
  videoTextSections?: { Header: string, Delay: number, Description: string }[];
  videoId: string
  width: number
  height?: number
  poster?: string
  isMacro?: boolean
}

interface IVideoAttributes {
  isAutoPlay: boolean
  isMuted: boolean
  isLoop: boolean
  isShowControls: boolean
}

const isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) ||
  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) /*&&
  !window.MSStream*/

export const Video = (props: IVideoProps) => {
  const [currentPlayTime, setCurrentPlayTime] = useState(0);
  const [isVideoReady, setVideoReady] = useState<boolean>(false);
  const videoWidth = props.width && props.width != undefined && props.width > 0 ? `${props.width}px` : '60%';
  !isIOS && useEffect(() => {
    const videoElement = document.getElementById(props.videoId) as HTMLMediaElement;
    if (videoElement != null) {
      const videoReadyHandler = () => {
        if (videoElement.readyState >= 3) {
          setVideoReady(true)
        }
      }
      videoElement.addEventListener('loadeddata', videoReadyHandler, { once: true });
      const handler = () => {
        let vTime = videoElement.currentTime;
        setCurrentPlayTime(vTime)
      }
      videoElement.addEventListener('timeupdate', handler);
      return () => {
        window.removeEventListener('loadeddata', videoReadyHandler);
        window.removeEventListener('timeupdate', handler);
      };
    }
  }, []);

  return <div className="videoSection">
    {!isIOS ? <LoadingOverlay
      active={!isVideoReady && !props.isShowControls}
      className={`video-loadingOverlay ${props.isMacro ? "macro" : ""} video-loadingOverlay-${props.videoId}`}
      classNamePrefix="_video_loading"
      spinner
      styles={{ width: videoWidth, height: props.height }}
    >
      <video id={props.videoId} src={props.videoUrl}
        preload="auto" className="video"
        autoPlay={props.isAutoPlay}
        controls={props.isShowControls}
        loop={props.isLoop}
        muted={props.isMuted}
        playsInline
        style={{ width: videoWidth, height: props.height }}>
        The video content is not supported.
      </video>
    </LoadingOverlay> : <VideoPlayer
      controls={true}
      autoplay={props.isAutoPlay}
      src={props.videoUrl}
      poster={props.poster}
      width={videoWidth}
      height={props.height}
    />}
    {props.videoTextSections && props.videoTextSections.length > 0 ?
      <div className="textSections">
        <ThreePoints className="threePoints" playTime={currentPlayTime} videoTextSections={props.videoTextSections} />
      </div>
      : <></>}
  </div>
}

export default Video;