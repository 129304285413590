import * as React from "react";
import { CarouselElementFactory, CarouselMode, IMedia } from "./CarouselElement";
import Carousel, { ResponsiveType } from "react-multi-carousel";
import { ArrowProps } from 'react-multi-carousel/lib/types'
import "react-multi-carousel/lib/styles.css";
import "./style.scss";


export interface ICarouselProps {
  medias: IMedia[],
  carouselId: string,
  isMobile?: boolean,
  autoPlaySpeed?: number,
  isAutoPlayOnMobile: boolean,
  isAutoPlayOnDesktop: boolean,
  hideArrow: boolean
  mode: CarouselMode
}

const responsive: ResponsiveType = {
  desktop: {
    breakpoint: { max: 5000, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const textOverImageresponsive: ResponsiveType = {
  desktop: {
    breakpoint: { max: 5000, min: 1024 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const getResponsiveConfig = (mode: CarouselMode) => {
  switch (mode) {
    case CarouselMode.CARD:
      return responsive
    case CarouselMode.FULL_WIDTH_IMAGE:
      return fullWidthResponsive
    case CarouselMode.TEXT_OVER_IMAGE:
      return textOverImageresponsive
  }
}

const fullWidthResponsive: ResponsiveType = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024
    },
    items: 1
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0
    },
    items: 1
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 464
    },
    items: 1
  }
}

const isMobile = window.innerWidth <= 768 ? true : false

type Direction = "left" | "right"
interface ICustomArrowProps extends ArrowProps {
  direction: Direction
}

export const CustomArrow: React.FC<ICustomArrowProps> = props => <i className={`fas fa-chevron-circle-${props.direction}`} onClick={props.onClick} />

export const WebFoxCarousel: React.FC<ICarouselProps> = props => (
  <Carousel
    className="webfox-carousel"
    swipeable={true}
    draggable={isMobile}
    showDots={props.mode == CarouselMode.FULL_WIDTH_IMAGE}
    responsive={getResponsiveConfig(props.mode)}
    ssr={true} // means to render carousel on server-side.
    infinite={true}
    autoPlay={isMobile ? props.isAutoPlayOnMobile : props.isAutoPlayOnDesktop}
    autoPlaySpeed={props.autoPlaySpeed * 1000}
    keyBoardControl={true}
    transitionDuration={1000}
    containerClass="carousel-container"
    removeArrowOnDeviceType={"mobile"}
    deviceType={isMobile ? "mobile" : "desktop"}
    dotListClass="custom-dot-list-style"
    itemClass="carousel-item-padding-40-px"
    customLeftArrow={<CustomArrow direction="left" />}
    customRightArrow={<CustomArrow direction="right" />}
    minimumTouchDrag={80}
    renderDotsOutside={false}
    arrows={!props.hideArrow}
  >
    {props.medias.map((x: IMedia, i: number) => <CarouselElementFactory carouselId={props.carouselId} key={i} media={x} mode={props.mode} />)}
  </Carousel>
)